import {
  Box,
  HStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Button,
  // Checkbox,
  // Flex,
  // FormControl,
  // FormLabel,
  // Heading,
  // Input,
  Link,
  Text,
  // Spinner,
  // Stack,
  // Image,
  useDisclosure,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

// METAGAME IMAGES
// https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/
// https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/
// https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/
// https://bafybeiggmevfo3wujbvt3xqcvaudhrbs5wug7heigsxnnbkueburlefena.ipfs.infura-ipfs.io/
// https://bafybeiaj5zzzxd6sj6xahk5s75frcwnyo7kkfuyq3lxflflv2al45xebtu.ipfs.infura-ipfs.io/
// https://bafybeifyn3uk25iitgdmqz25a6xbkbwf72os2ssogayyllsrctm5ify23q.ipfs.infura-ipfs.io/
// https://bafybeiduvjqictrruk3zqbxnyep3egjmqqtosoksklvpm7cna3l7q7z4xy.ipfs.infura-ipfs.io/
// https://bafybeiaa4sm4arxmcpa3cxk4wav3nlvdjfry2eayba4sleg6m4qenyhe4y.ipfs.infura-ipfs.io/
// https://bafybeihyswfympur6dq4qswdfq5kwb6e5a2wmi4t4gvxbmxksxhkyc4mhy.ipfs.infura-ipfs.io/
// https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/

function AuthenticationForm3() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div style={{ backgroundImage: `url(${background})` }}>

      <Box
        backgroundImage="url('https://bafybeihx67d3lfjiaubglcdijganeolkkagxegtt6wuztptgamnpjo2xvi.ipfs.infura-ipfs.io/')"
        // backgroundImage="url('https://bafybeihlvdhwtb54nccbh3on3is2jwjlpzijhmb4jxftgadlfk45rcdd6a.ipfs.infura-ipfs.io/')"


        backgroundSize="cover"
        backgroundPosition="cover"
        backgroundRepeat="no-repeat"
        opacity="0.99"
        p={4}
        display={{ md: "flex" }}>


        <Box mt={{ base: 4, md: 0 }} ml={{ md: 12 }}>
          <Text
            fontWeight="bold"
            // textTransform="uppercase"
            fontSize="2xl"
            letterSpacing="wide"
            color="gray.100"
          >
            MetaCollab Gig Locking
          </Text>
          <Link
            mt={1}
            display="block"
            fontSize="lg"
            lineHeight="normal"
            fontWeight="semibold"
            href=" # "
          >
            Unresolvable Funder-Doer Dispute - Lock the Gig 
          </Link>
          <br />
          <Box>
            <HStack>
              <Button colorScheme="purple" onClick={onOpen}>
                {" "}
                Lock a Gig Now{" "}
              </Button>
              <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerHeader borderBottomWidth="1px">
                    {" "}
                    Basic Drawer{" "}
                  </DrawerHeader>
                  <DrawerBody>
                    <p>
                      {" "}
                      MetaGame provides value and service supporting the fourth
                      industrial revolution. Web3 is a set of technologies that
                      publicly interoperate through blockchain and node based
                      computing systems.{" "}
                    </p>
                    <p>
                      {" "}
                      MetaGame provides value and service supporting the fourth
                      industrial revolution. Web3 is a set of technologies that
                      publicly interoperate through blockchain and node based
                      computing systems.{" "}
                    </p>
                    <p>
                      {" "}
                      MetaGame provides value and service supporting the fourth
                      industrial revolution. Web3 is a set of technologies that
                      publicly interoperate through blockchain and node based
                      computing systems.{" "}
                    </p>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </HStack>
          </Box>
          <br />
          <Text mt={2} color="gray.100">
            Getting a new organization off the ground takes patience. Here are a
            few ways MetaCollab members are found.
          </Text>
        </Box>
      </Box>


    </div>
  );
}
export default AuthenticationForm3;
