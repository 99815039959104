import React from "react";
import {
  Box,
  Image,
  Text,
  Link,
  // Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  // FormControl,
  // FormLabel,
  // FormHelperText,
  Stack,
  HStack,
  // Radio,
  // RadioGroup,
  Spinner,
  Input,
  Button,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

// FORM DATA AND SENT TO DISCORD WEBHOOK
// Use the webhook id and token, to get these informations go to Server Settings>Integrations>Webhooks and click on the pretended webhook and copy the webhook URL. Paste the URL on google to see the webhook's ID and Token.

// const id = '';
// const token = '';
// const webhook = new Discord.WebhookClient(id, token);
// webhook.send('Your message');

// form = document.getElementById("submit-form");
// sucessMsg = document.getElementById("success");

// function sucess(event) {
// sucessMsg.style.display = "inline"
// event.preventDefault()
// console.log("form data:", getData())

// }

// function getData()
// {
// const formData = new FormData(form),
// ret = {};

// for(let [name, value] of formData.entries())
// ret[name] = value;

//  return ret;
//  }

// form.addEventListener("submit" , sucess )

function FormLayout4() {
  const format = (val) => val + `  MATIC`;
  const parse = (val) => val.replace(/^\$/, "");
  const [value, setValue] = React.useState("100");
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <Box
        backgroundImage="url('https://bafybeihx67d3lfjiaubglcdijganeolkkagxegtt6wuztptgamnpjo2xvi.ipfs.infura-ipfs.io/')"
        // backgroundImage="url('https://bafybeihlvdhwtb54nccbh3on3is2jwjlpzijhmb4jxftgadlfk45rcdd6a.ipfs.infura-ipfs.io/')"


        backgroundSize="cover"
        backgroundPosition="cover"
        backgroundRepeat="no-repeat"
        opacity="0.99"
        p={4}
        display={{ md: "flex" }}>

        <Box flexShrink={0}>
          <Image
            maxW="400px"
            borderRadius="lg"
            width={{ md: 40 }}
            src=

            // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
            // " https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "
            // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/
            // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/
            // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/
            // " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
            // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
            // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
            // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
            // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
            // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
            // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
            // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
            " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
            // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
            // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
            // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
            // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"


            alt=" MetaCollab "
          />
        </Box>

        <Box mt={{ base: 4, md: 0 }} ml={{ md: 8 }}>
          <br />
          <Text
            fontWeight="bold"
            textTransform="uppercase"
            fontSize="3xl"
            letterSpacing="wide"
            color="gray.200"
          >
            Gig Locking
          </Text>

          <HStack>
            <Box>
              <Link
                mt={1}
                display="block"
                fontSize="lg"
                lineHeight="normal"
                fontWeight="semibold"
                href=" # "
              >
                Resolution Service Paid in (MATIC tokens)
              </Link>
              <NumberInput
                w="200px"
                onChange={(valueString) => setValue(parse(valueString))}
                value={format(value)}
                max={10000}
                min={0}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>

            <Box>
              <Button ml="5" colorScheme="blue" size="sm">
                Submit
              </Button>
            </Box>
          </HStack>
        </Box>
      </Box>

      <Box
        backgroundImage="url('https://bafybeihx67d3lfjiaubglcdijganeolkkagxegtt6wuztptgamnpjo2xvi.ipfs.infura-ipfs.io/')"
        // backgroundImage="url('https://bafybeihlvdhwtb54nccbh3on3is2jwjlpzijhmb4jxftgadlfk45rcdd6a.ipfs.infura-ipfs.io/')"


        backgroundSize="cover"
        backgroundPosition="cover"
        backgroundRepeat="no-repeat"
        opacity="0.99"
        p={4}
        display={{ md: "flex" }}>


        <Box flexShrink={0}>
          <Image
            maxW="200px"
            // borderRadius="lg"
            width={{ md: 40 }}
            src=
            // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
            // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io"
            // " https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "
            // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
            // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
            // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
            " https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
            // " https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
            // " https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
            // " https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
            // " https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
            // " https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
            // " https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
            // " https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
            // " https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
            // " https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
            // " https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
            // " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
            // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"

            alt=" MetaCollab "
          />
        </Box>
        <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
          <Link
            mt={1}
            display="block"
            fontSize="lg"
            lineHeight="normal"
            fontWeight="semibold"
            href=" # "
          >
            Locking a Gig Requires Third-Party Resolution
          </Link>
          <Text mt={2} color="gray.100">
            Getting a new organization off the ground takes patience. Here are
            some ways MetaCollab members are found.
          </Text>
          <Stack spacing={3}>
            <Input
              color="gray.900"
              bg="green.800"
              variant="solid"
              placeholder="Reasons, causes or conditions resulting in the unresolvable dispute."
            />
            <Input
              variant="filled"
              placeholder=" Acceptable resolution if opposing party agreed. "
              bg="purple.800"
              color="yellow"
            />
            <br />

          </Stack>


          <br />
          <Button colorScheme="messenger" size="md" type="submit">
            Lock a Gig Now
          </Button>

          <Stack justify="right" direction="row" spacing={4}>
            <Spinner
              thickness="2px"
              speed="2s"
              emptyColor="gray.200"
              color="pink.500"
              size="lg"
            />
            <Spinner
              thickness="2px"
              speed="3s"
              emptyColor="gray.200"
              color="blue.500"
              size="lg"
            />
            <Spinner
              thickness="2px"
              speed="4s"
              emptyColor="gray.200"
              color="purple.500"
              size="lg"
            />
          </Stack>
          <Stack justify="right" direction="row" spacing={4}>
            <Text mt={2} color="gray.100">
              Please wait while your transaction processes. It may take a while.
            </Text>
          </Stack>
        </Box>
      </Box>
    </div>
  );
}
export default FormLayout4;
